body.bright {
  @apply bg-base-100 text-neutral;
  background-image: radial-gradient(#100114 1px, transparent 0), linear-gradient(165deg, #f2ffff 50%, rgba(0, 255, 255, 0.24));
  background-size: 35px 35px, 100% 100%;
}
body.bright ::-webkit-scrollbar-track {
  @apply border-neutral;
}
body.bright ::-webkit-scrollbar-thumb {
  @apply bg-secondary border-neutral;
}
body.bright ::-webkit-scrollbar-corner {
  border-radius: 0 0 6px 0;
}
body.bright ::-webkit-scrollbar-button {
  @apply bg-secondary;
}
body.bright ::-webkit-scrollbar-button:vertical:increment, body.bright ::-webkit-scrollbar-button:vertical:decrement {
  border-color: #100114;
}
body.bright ::-webkit-scrollbar-button:vertical:increment {
  background-image: linear-gradient(60deg, #f2ffff 35%, transparent 35%), linear-gradient(180deg, #f2ffff 35%, transparent 35%), linear-gradient(300deg, #f2ffff 35%, transparent 35%), linear-gradient(60deg, #100114 calc(35% + 1px), transparent 35%), linear-gradient(180deg, #100114 calc(35% + 1px), transparent 35%), linear-gradient(300deg, #100114 calc(35% + 1px), transparent 35%);
}
body.bright ::-webkit-scrollbar-button:vertical:decrement {
  background-image: linear-gradient(120deg, #f2ffff 35%, transparent 35%), linear-gradient(0deg, #f2ffff 35%, transparent 35%), linear-gradient(240deg, #f2ffff 35%, transparent 35%), linear-gradient(120deg, #100114 calc(35% + 1px), transparent 35%), linear-gradient(0deg, #100114 calc(35% + 1px), transparent 35%), linear-gradient(240deg, #100114 calc(35% + 1px), transparent 35%);
}
body.bright ::-webkit-scrollbar-button:horizontal:increment, body.bright ::-webkit-scrollbar-button:horizontal:decrement {
  border-color: #100114;
}
body.bright ::-webkit-scrollbar-button:horizontal:increment {
  background-image: linear-gradient(210deg, #f2ffff 35%, transparent 35%), linear-gradient(90deg, #f2ffff 35%, transparent 35%), linear-gradient(330deg, #f2ffff 35%, transparent 35%), linear-gradient(210deg, #100114 calc(35% + 1px), transparent 35%), linear-gradient(90deg, #100114 calc(35% + 1px), transparent 35%), linear-gradient(330deg, #100114 calc(35% + 1px), transparent 35%);
}
body.bright ::-webkit-scrollbar-button:horizontal:decrement {
  background-image: linear-gradient(140deg, #f2ffff 35%, transparent 35%), linear-gradient(270deg, #f2ffff 35%, transparent 35%), linear-gradient(30deg, #f2ffff 35%, transparent 35%), linear-gradient(140deg, #100114 calc(35% + 1px), transparent 35%), linear-gradient(270deg, #100114 calc(35% + 1px), transparent 35%), linear-gradient(30deg, #100114 calc(35% + 1px), transparent 35%);
}
body.bright a {
  @apply text-primary hover:text-accent;
}
body.bright ul li::before {
  @apply border-neutral bg-secondary/25;
}
body.bright .switch .switchHandle {
  @apply bg-secondary border border-neutral;
  border-radius: 100%;
  left: 15px;
}
body.bright .switch .switchHandle::after {
  display: none;
}
body.bright .switch .switchBack {
  @apply border border-neutral bg-base-100;
  border-radius: 50px;
  box-shadow: inset 3px 3px #ff1493, inset 3px 3px 0 1px #100114;
}
body.bright header {
  @apply border-neutral;
}
body.bright .page h1 {
  @apply bg-base-100 border-neutral rounded-lg;
  box-shadow: 7px 7px #ff1493, 7px 7px 0 1px #100114;
}
body.bright .page .window h2 {
  @apply border-neutral bg-white;
}
body.bright .page .window .featImg {
  @apply border-neutral;
}
body.bright .page .window .postFoot {
  @apply border-neutral;
}
body.bright .post .date,
body.bright .post .categories {
  @apply border-neutral bg-base-100 rounded-t-lg;
}
body.bright .post .postWrap {
  @apply bg-base-100 border-neutral rounded-lg;
  box-shadow: 7px 7px #a300ef, 7px 7px 0 1px #100114;
}
body.bright .post figure {
  @apply border-neutral rounded-lg overflow-hidden;
  box-shadow: 7px 7px #ff1493, 7px 7px 0 1px #100114;
}
body.bright .post .window figure.alignfull {
  border-radius: 0;
  box-shadow: none;
}
body.bright .post .window figure.alignwide {
  margin-left: calc((3rem + 4px) * -1);
}
body.bright .home .logoBlock .logoInner {
  @apply bg-base-100 border border-neutral rounded-lg;
  box-shadow: 7px 7px #ff1493, 7px 7px 0 1px #100114;
}
body.bright .home .logoBlock .logoInner hr {
  @apply border border-primary;
}
body.bright .home .switch .switchHandle {
  left: 30px;
}
body.bright .home .portfolio .icon {
  @apply rounded-md border border-neutral overflow-hidden;
  box-shadow: 4px 4px #ff1493, 4px 4px 0 1px #100114;
}
body.bright .home .portfolio .icon:hover {
  box-shadow: 2px 2px #ff1493, 2px 2px 0 1px #100114;
}
body.bright .home .desktopIcons .icon a {
  @apply text-neutral;
}
body.bright .home .about .techWrap .hoverBox {
  @apply bg-base-100 border-neutral rounded-md;
}
body.bright .home .about .techWrap .hoverBox::after {
  @apply bg-base-100 border-neutral;
}
body.bright .window {
  @apply bg-base-100 border border-neutral rounded-lg;
  box-shadow: 7px 7px #ff1493, 7px 7px 0 1px #100114;
}
body.bright .window .cornerWrap {
  @apply rounded-lg;
}
body.bright .window .menuBar {
  @apply bg-white border-b border-neutral;
}
body.bright .window .inner {
  @apply bg-base-100 rounded-md;
}
body.bright .window .menuBar {
  @apply bg-base-100;
}
body.bright .window .bar {
  @apply bg-secondary overflow-hidden border-b border-neutral;
  border-radius: 6px 6px 0 0;
}
body.bright .window .bar a {
  @apply text-neutral;
}
body.bright .window .bar .barButton {
  @apply bg-base-100 border-r border-neutral;
}
body.bright .window .bar .barButton .dash {
  @apply bg-primary;
}
body.bright .window .bar .arrowBtn {
  @apply bg-base-100 border-l border-neutral;
}
body.bright .window .corner {
  @apply border border-neutral;
}
body.bright .window .tags a.tag {
  @apply btn-outline bg-secondary/15 hover:bg-secondary/50 hover:text-neutral;
}
body.bright .clock .inner {
  @apply bg-base-100;
}
body.bright .clock .inner .mark::before {
  @apply border-primary;
}
body.bright .clock .inner .mark:nth-of-type(3n + 3)::before {
  @apply bg-primary;
}
body.bright .clock .center {
  @apply bg-base-100 border border-neutral;
}
body.bright .clock .second::before {
  @apply border border-neutral;
}
body.bright .clock .minute::before,
body.bright .clock .hour::before {
  @apply border border-primary bg-base-100;
}
body.bright .calc .screen {
  @apply bg-secondary/25 border-neutral rounded-md text-neutral;
}
body.bright .calc button {
  @apply border-neutral hover:bg-primary/15 active:bg-secondary/20;
}
body.bright .calc .operand {
  @apply border-primary text-primary;
}
body.bright .project .gallery img {
  @apply rounded-md border-neutral;
  box-shadow: 4px 4px #ff1493, 4px 4px 0 1px #100114;
}
body.bright .project .gallery img:hover {
  cursor: pointer;
  box-shadow: 2px 2px #ff1493, 2px 2px 0 1px #100114;
}
body.bright .project .gallery .innerWrap {
  @apply bg-secondary/15;
}
body.bright .project .body .content h2 {
  @apply bg-transparent;
}
body.bright .project .tech .repo {
  @apply bg-secondary/25 border-neutral;
}
body.bright .spinner {
  @apply border-secondary border-r-secondary/25;
}
body.bright .bigImg {
  @apply bg-accent/50 text-secondary;
}
body.bright .bigImg svg {
  @apply hover:text-accent;
  filter: drop-shadow(2px 2px #100114);
}
body.bright .bigImg img {
  @apply rounded-lg border border-neutral;
}
body.bright .contact .main form label {
  @apply border-neutral;
}
body.bright .contact .main form button {
  @apply btn-primary text-white hover:btn-accent border-neutral;
  box-shadow: 4px 4px #00ffff, 4px 4px 0 1px #100114;
}
body.bright .contact .main form button:hover {
  box-shadow: 2px 2px #00ffff, 2px 2px 0 1px #100114;
}

@media (max-width: 901px) {
  body.bright .page.project .window {
    box-shadow: none;
  }
}
body.dark {
  @apply bg-neutral text-base-100;
  background-image: linear-gradient(165deg, #100114 50%, rgba(140, 5, 77, 0.4));
}
body.dark * ::-webkit-scrollbar-track {
  @apply border-primary;
}
body.dark * ::-webkit-scrollbar-thumb {
  @apply bg-neutral block relative;
  border-color: #008989;
}
body.dark * ::-webkit-scrollbar-corner {
  border-color: #ff1493;
}
body.dark * ::-webkit-scrollbar-button:vertical:increment, body.dark * ::-webkit-scrollbar-button:vertical:decrement {
  border-color: #ff1493;
}
body.dark * ::-webkit-scrollbar-button:vertical:increment {
  background-image: linear-gradient(60deg, #100114 35%, transparent 35%), linear-gradient(180deg, #100114 35%, transparent 35%), linear-gradient(300deg, #100114 35%, transparent 35%), linear-gradient(60deg, #ff1493 calc(35% + 1px), transparent 35%), linear-gradient(180deg, #ff1493 calc(35% + 1px), transparent 35%), linear-gradient(300deg, #ff1493 calc(35% + 1px), transparent 35%);
}
body.dark * ::-webkit-scrollbar-button:vertical:decrement {
  background-image: linear-gradient(120deg, #100114 35%, transparent 35%), linear-gradient(0deg, #100114 35%, transparent 35%), linear-gradient(240deg, #100114 35%, transparent 35%), linear-gradient(120deg, #ff1493 calc(35% + 1px), transparent 35%), linear-gradient(0deg, #ff1493 calc(35% + 1px), transparent 35%), linear-gradient(240deg, #ff1493 calc(35% + 1px), transparent 35%);
}
body.dark * ::-webkit-scrollbar-button:horizontal:increment, body.dark * ::-webkit-scrollbar-button:horizontal:decrement {
  border-color: #ff1493;
}
body.dark * ::-webkit-scrollbar-button:horizontal:increment {
  background-image: linear-gradient(60deg, #f2ffff 35%, transparent 35%), linear-gradient(180deg, #f2ffff 35%, transparent 35%), linear-gradient(300deg, #f2ffff 35%, transparent 35%), linear-gradient(60deg, #100114 calc(35% + 1px), transparent 35%), linear-gradient(180deg, #100114 calc(35% + 1px), transparent 35%), linear-gradient(300deg, #100114 calc(35% + 1px), transparent 35%);
}
body.dark * ::-webkit-scrollbar-button:horizontal:decrement {
  background-image: linear-gradient(120deg, #f2ffff 35%, transparent 35%), linear-gradient(0deg, #f2ffff 35%, transparent 35%), linear-gradient(240deg, #f2ffff 35%, transparent 35%), linear-gradient(120deg, #100114 calc(35% + 1px), transparent 35%), linear-gradient(0deg, #100114 calc(35% + 1px), transparent 35%), linear-gradient(240deg, #100114 calc(35% + 1px), transparent 35%);
}
body.dark * ::-webkit-scrollbar-button:horizontal:increment, body.dark * ::-webkit-scrollbar-button:horizontal:decrement {
  border-color: #100114;
}
body.dark * ::-webkit-scrollbar-button:horizontal:increment {
  background-image: linear-gradient(210deg, #100114 35%, transparent 35%), linear-gradient(90deg, #100114 35%, transparent 35%), linear-gradient(330deg, #100114 35%, transparent 35%), linear-gradient(210deg, #ff1493 calc(35% + 1px), transparent 35%), linear-gradient(90deg, #ff1493 calc(35% + 1px), transparent 35%), linear-gradient(330deg, #ff1493 calc(35% + 1px), transparent 35%);
}
body.dark * ::-webkit-scrollbar-button:horizontal:decrement {
  background-image: linear-gradient(140deg, #100114 35%, transparent 35%), linear-gradient(270deg, #100114 35%, transparent 35%), linear-gradient(30deg, #100114 35%, transparent 35%), linear-gradient(140deg, #ff1493 calc(35% + 1px), transparent 35%), linear-gradient(270deg, #ff1493 calc(35% + 1px), transparent 35%), linear-gradient(30deg, #ff1493 calc(35% + 1px), transparent 35%);
}
body.dark a {
  @apply text-secondary hover:text-accent;
}
body.dark ul li::before {
  @apply border-primary bg-primary/40;
}
body.dark .switch .switchHandle {
  @apply bg-neutral border border-primary left-0;
}
body.dark .switch .switchHandle::after {
  @apply absolute block border border-primary;
  content: "";
  width: 9px;
  height: 9px;
  left: 5px;
  top: 5px;
}
body.dark .switch .switchBack {
  @apply border border-primary;
}
body.dark header {
  @apply border-primary bg-primary/5;
}
body.dark .page h1 {
  @apply border-primary bg-neutral text-secondary;
  box-shadow: 3px 3px #100114, 7px 10px #ff1493;
}
body.dark .page .window h2 {
  @apply border-primary bg-primary/10;
}
body.dark .page .window .featImg {
  @apply border-primary;
}
body.dark .page .window .postFoot {
  @apply border-primary;
}
body.dark .post .date,
body.dark .post .categories {
  @apply bg-neutral border-primary;
}
body.dark .post .postWrap {
  @apply border-primary bg-neutral text-secondary;
}
body.dark .post figure {
  @apply border-primary;
  box-shadow: 4px 8px #100114, 5px 7px #ff1493, 3px 9px #ff1493;
}
body.dark .home .logoBlock .logoInner {
  @apply border border-primary bg-neutral text-secondary;
  box-shadow: 3px 3px #100114, 7px 10px #ff1493;
}
body.dark .home .logoBlock .logoInner hr {
  @apply border border-primary;
}
body.dark .home .switch .switchHandle::after {
  width: 19px;
  height: 19px;
  left: 9px;
  top: 9px;
}
body.dark .home .switch .switchBack {
  @apply border border-primary;
}
body.dark .home .blog .post {
  @apply border-accent/40;
}
body.dark .home .blog .post .date {
  @apply text-secondary;
}
body.dark .home .about .techWrap .hoverBox {
  @apply bg-neutral border-primary;
}
body.dark .home .about .techWrap .hoverBox::after {
  @apply bg-neutral border-primary;
}
body.dark .window {
  @apply bg-neutral border border-primary;
}
body.dark .window .bar {
  background: linear-gradient(transparent, transparent 7px, #ff1493 7px, #ff1493 8px, transparent 8px, transparent 13px, #ff1493 13px, #ff1493 14px, transparent 14px, transparent 19px, #ff1493 19px, #ff1493 20px, transparent 20px, transparent 25px, #ff1493 25px, #ff1493 26px, transparent 26px);
  background-position: center center;
  background-repeat: no-repeat;
}
body.dark .window .bar .title {
  @apply px-6 bg-neutral items-center flex border-x border-primary;
  height: 33px;
}
body.dark .window .bar .title p,
body.dark .window .bar .title a {
  @apply m-0;
}
body.dark .window .menuBar {
  @apply bg-neutral border-b border-primary;
}
body.dark .window .inner {
  @apply text-primary bg-neutral border border-primary;
}
body.dark .window .corner {
  @apply border border-primary;
}
body.dark .window .icon {
  @apply border-2 border-primary;
}
body.dark .window .tags a.tag {
  @apply btn-neutral border-primary text-primary bg-primary/10;
}
body.dark .window .tags a.tag:hover {
  @apply btn-neutral border-secondary text-secondary bg-secondary/10;
}
body.dark .bar {
  @apply bg-neutral border-b border-primary;
}
body.dark .bar a {
  @apply text-primary;
}
body.dark .bar .barButton {
  @apply bg-neutral border-r border-primary;
}
body.dark .bar .barButton .dash {
  @apply bg-primary;
}
body.dark .bar .arrowBtn {
  @apply bg-neutral border-l border-primary;
}
body.dark .desktopIcons {
  @apply text-secondary;
}
body.dark .desktopIcons .icon svg path {
  fill: #ff1493;
}
body.dark .clock {
  @apply text-primary;
}
body.dark .clock .inner .mark::before {
  @apply border-primary;
}
body.dark .clock .inner .center {
  @apply bg-neutral border border-primary;
}
body.dark .clock .inner .second::before {
  @apply border-l-2 border-primary;
}
body.dark .clock .inner .minute::before {
  @apply border border-secondary bg-neutral;
}
body.dark .clock .inner .hour::before {
  @apply border-2 border-secondary bg-neutral;
}
body.dark .clock .timeDisplay {
  @apply text-primary;
}
body.dark .calc .screen {
  @apply border-primary bg-primary/10;
}
body.dark .calc button {
  @apply bg-neutral hover:bg-secondary/15 hover:text-base-100 active:bg-primary/15 active:text-base-100;
}
body.dark .calc .number {
  @apply border-secondary text-secondary;
}
body.dark .calc .operand {
  @apply border-primary text-primary;
}
body.dark .project .gallery img {
  @apply border-primary;
}
body.dark .project .gallery .innerWrap {
  @apply bg-primary/10;
}
body.dark .project .tech .repo {
  @apply bg-primary/10 border-primary;
}
body.dark .spinner {
  @apply border-accent border-r-accent/25;
}
body.dark .bigImg {
  @apply bg-neutral/50 text-secondary;
}
body.dark .bigImg svg {
  @apply hover:text-accent;
  filter: drop-shadow(2px 2px #a300ef);
}
body.dark .bigImg img {
  @apply border border-primary;
}
body.dark .contact .main form label {
  @apply border-primary;
}
body.dark .contact .main form button {
  @apply btn-neutral hover:btn-primary border-primary;
  border-radius: 0;
}

@font-face {
  font-family: "Neon Sans";
  src: url("../fonts/NeonSans.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "DotGothic16";
  src: url("../fonts/DotGothic16-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  transition: all 0.15s ease-in-out;
}
* ::-webkit-scrollbar {
  @apply w-3;
}
* ::-webkit-scrollbar:vertical {
  margin-right: 1rem;
}
* ::-webkit-scrollbar-track {
  border: 1px solid black;
}
* ::-webkit-scrollbar-track:vertical {
  border-right: none;
}
* ::-webkit-scrollbar-track:horizontal {
  border-bottom: none;
}
* ::-webkit-scrollbar-thumb {
  @apply bg-primary;
  border: 1px solid black;
  border-right: none;
}
* ::-webkit-scrollbar-corner {
  border: 1px solid #100114;
  border-right: 0;
  border-bottom: 0;
}
* ::-webkit-scrollbar-button {
  @apply block h-3 w-3;
}
* ::-webkit-scrollbar-button:start:increment, * ::-webkit-scrollbar-button:end:decrement {
  display: none;
}
* ::-webkit-scrollbar-button:vertical:increment, * ::-webkit-scrollbar-button:vertical:decrement {
  border-left: 1px solid black;
}
* ::-webkit-scrollbar-button:horizontal:increment, * ::-webkit-scrollbar-button:horizontal:decrement {
  border-top: 1px solid black;
}

body {
  font-family: "DotGothic16";
  font-size: 16px;
  min-height: 100vh;
  min-width: 100%;
  position: relative;
  overflow: hidden;
}

#root {
  min-width: 100%;
  height: 100vh;
  overflow: hidden;
}

#container {
  height: 100vh;
  width: 100%;
  overflow: auto;
}
#container.pageContain {
  height: calc(100vh - 53px);
  margin-top: 53px;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

header {
  @apply px-5 py-3 border-b backdrop-blur-sm w-full fixed;
  z-index: 999999;
}
header .mobile {
  @apply hidden;
}
header .headerWrap {
  @apply w-full flex justify-between items-center;
}
header nav {
  @apply w-full text-lg flex gap-9 items-center;
}

.switch {
  @apply relative flex justify-center items-center;
  height: 22px;
  cursor: pointer;
}
.switch .switchHandle {
  @apply absolute;
  height: 21px;
  width: 21px;
  transition: all 0.25s ease-in-out;
}
.switch .switchBack {
  height: 10px;
  width: 35px;
}

.home .logoBlock {
  @apply absolute px-2 py-5;
  min-height: 150px;
  width: 450px;
  z-index: 150;
  top: calc(50% - 85px + 2rem);
  left: calc(55% - 450px);
}
.home .logoBlock .logoInner {
  @apply flex flex-col justify-center items-center h-full p-3;
}
.home .logoBlock h1 {
  @apply text-5xl;
}
.home .logoBlock h2 {
  @apply text-2xl uppercase mb-4;
}
.home .logoBlock hr {
  @apply my-2 mx-0 w-11/12;
}
.home .draggable {
  @apply absolute;
}
.home .portfolio {
  left: 3rem;
  top: 2rem;
  width: calc(55% - 4rem);
  height: calc(50% - 85px);
  z-index: 3;
}
.home .portfolio .innerWrap {
  @apply flex flex-wrap justify-center gap-3 items-center;
}
.home .portfolio .item {
  @apply flex flex-col items-center p-2 text-center;
  height: 150px;
}
.home .portfolio .item .icon {
  @apply mb-3 w-fit;
  height: 90px;
  max-width: 100%;
  margin-bottom: 5px;
}
.home .portfolio .item .icon img {
  height: 100%;
  width: auto;
}
.home .blog {
  @apply h-1/2;
  top: 4rem;
  z-index: 1;
  right: 5%;
  width: 35%;
}
.home .blog .menuBar p {
  cursor: pointer;
}
.home .blog .menuBar p::first-letter {
  font-weight: 900;
  text-decoration: underline;
}
.home .blog .innerWrap {
  @apply p-0;
}
.home .blog .innerWrap .post {
  @apply border-y p-2;
}
.home .blog .innerWrap .post:first-of-type {
  @apply border-t-0;
}
.home .blog .innerWrap h2 {
  @apply text-xl;
}
.home .blog .innerWrap .date {
  @apply text-sm opacity-50 py-1 px-5;
}
.home .blog .innerWrap .readMore {
  @apply ml-5;
}
.home .blog .innerWrap.tags {
  align-items: start;
}
.home .blog .tags,
.home .blog .categories {
  @apply p-3 flex flex-col gap-3;
}
.home .blog .tags .item,
.home .blog .categories .item {
  @apply flex items-center gap-2;
}
.home .about {
  width: 55%;
  height: 32%;
  z-index: 3;
  bottom: 3rem;
  left: 43%;
}
.home .about .innerWrap {
  overflow: visible;
  height: calc(100% - 35px);
}
.home .about .techWrap {
  @apply flex flex-wrap gap-3 items-center justify-center;
  padding: 1rem 5rem;
  height: 100%;
}
.home .about .techWrap .item {
  height: 30%;
  position: relative;
}
.home .about .techWrap img {
  max-height: 100%;
  width: auto;
}
.home .about .techWrap .hoverBox {
  @apply border p-3 text-center;
  position: absolute;
  z-index: 99;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
}
.home .about .techWrap .hoverBox::after {
  @apply absolute h-4 w-4 border-t border-l;
  content: "";
  top: -9px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.home .desktopIcons {
  @apply w-2/5 grid grid-cols-4 grid-rows-5 absolute bottom-5;
  height: 500px;
}
.home .desktopIcons .icon,
.home .desktopIcons .icon a {
  @apply flex flex-col justify-center items-center gap-2;
  cursor: pointer;
}
.home .desktopIcons .icon svg,
.home .desktopIcons .icon a svg {
  height: 2.5rem;
  width: auto;
}
.home .desktopIcons .socialIcon {
  grid-row: 3/4;
}
.home .desktopIcons .socialIcon:nth-of-type(3) {
  grid-column: 3/4;
}
.home .desktopIcons .socialIcon:nth-of-type(4) {
  grid-column: 4/5;
}
.home .desktopIcons .gitHub {
  grid-row: 2/3;
  grid-column: 2/3;
}
.home .desktopIcons .menuIcon {
  grid-row: 5/6;
}
.home .desktopIcons .clockIcon {
  grid-row: 4/5;
  grid-column: 1/2;
}
.home .desktopIcons .calcIcon {
  grid-row: 4/5;
  grid-column: 2/3;
}
.home .desktopIcons .contactIcon {
  grid-row: 4/5;
  grid-column: 4/5;
}
.home .switch {
  @apply absolute top-2.5 right-2.5;
  height: 44px;
}
.home .switch .switchHandle {
  height: 40px;
  width: 40px;
}
.home .switch .switchBack {
  height: 20px;
  width: 70px;
}

.page {
  @apply py-5 px-5;
}
.page h1 {
  @apply text-4xl w-2/3 px-5 pt-3 pb-4 ml-8 border mb-16;
}
.page .window {
  @apply w-9/12 -mt-4;
}
.page .window .innerWrap {
  @apply p-0;
}
.page .window h2 {
  @apply text-lg uppercase px-8 py-2 border-b;
}
.page .window .subHead {
  @apply flex justify-between items-center py-2 px-3 text-sm opacity-50;
}
.page .window .featImg {
  @apply overflow-hidden object-cover border-b;
  max-height: 200px;
}
.page .window .body .text {
  @apply p-5;
}
.page .window .categories {
  @apply flex gap-3;
}
.page .window .postFoot {
  @apply p-2 pb-3 border-t;
}
.page .window .postFoot .tags {
  @apply flex gap-3;
  min-height: 24px;
}
.page .posts {
  @apply mx-auto;
  width: 1048px;
}
.page .posts .window .menuBar {
  @apply flex justify-between items-center w-full flex-wrap;
}
.page .posts .window:nth-of-type(2n) {
  @apply ml-auto;
}

.post .menuBar {
  @apply justify-between;
}
.post .tags,
.post .categories {
  @apply flex gap-3;
}
.post .tags p,
.post .categories p {
  @apply m-0;
}
.post p {
  @apply mb-5;
}
.post figure {
  @apply border mb-8 w-fit mx-auto;
  max-width: 80%;
}
.post figure.alignright {
  float: right;
  margin-right: -5%;
  max-width: 50%;
  margin-left: 1.5rem;
}
.post figure.alignleft {
  float: left;
  margin-left: -5%;
  max-width: 50%;
  margin-right: 2rem;
}
.post figure.alignfull, .post figure.alignwide {
  width: calc(100% + 2rem);
  z-index: 10;
  margin-left: -1rem;
  max-width: unset;
}
.post figure.alignfull img, .post figure.alignwide img {
  width: 100%;
  height: auto;
  margin-left: 0;
  margin-right: 0;
}
.post figure.alignwide {
  width: calc(100% + 6rem);
  margin-left: -3rem;
}
.post .window {
  @apply mx-auto w-4/5;
  max-width: 1048px;
  overflow-x: visible;
}
.post .window .inner {
  overflow: visible !important;
}
.post .window .innerWrap {
  @apply p-4;
  overflow: visible;
}
.post .window .featImg {
  @apply border-t-0 -mt-4 -ml-4 h-fit mb-5;
  width: calc(100% + 2rem);
  max-height: unset;
}
.post .window .postFoot {
  @apply pb-3 px-5 text-sm;
}
.post .window .postFoot p {
  @apply opacity-50 m-0;
}

.projects {
  @apply flex flex-col items-center mx-auto;
  width: 1048px;
}
.projects .window {
  width: fit-content;
  max-width: 50%;
}
.projects .window .inner {
  @apply w-fit h-fit;
  display: block;
  overflow: hidden;
  max-width: calc(100% - 20px);
}
.projects .window .inner a {
  @apply h-full w-fit;
}
.projects .window .inner img {
  max-width: 100%;
  height: auto;
  width: auto;
}
.projects .window .inner .innerWrap {
  max-height: unset;
  width: fit-content;
  height: fit-content;
  overflow: hidden;
}
.projects .window:nth-of-type(2n + 1) {
  margin-right: 45%;
}
.projects .window:nth-of-type(2n) {
  margin-left: 45%;
}

.project .window.main {
  overflow: visible;
}
.project .window.main .inner,
.project .window.main .innerWrap {
  overflow: visible;
}
.project .window.main .demo h1 {
  @apply mt-3 mx-auto text-center;
}
.project .window.main .body {
  @apply flex gap-3;
}
.project .window.main .content {
  @apply grow;
  max-width: 600px;
}
.project .window.main .tech {
  @apply w-fit uppercase mb-5 mt-0;
  margin-right: -3rem;
  min-width: 250px;
}
.project .window.main .tech ul {
  @apply p-3;
}
.project .window.main .tech ul li {
  @apply flex gap-2 items-center;
}
.project .window.main .tech ul li::before {
  @apply h-3 w-3 border block;
  margin-top: 2px;
  border-radius: 100%;
  content: "";
}
.project .window.main .tech .repo {
  @apply p-3 border-t mt-auto;
}
.project .window.main .featImg {
  @apply border-0 flex justify-center items-center mb-0;
}
.project .window.main .featImg img {
  width: 100%;
  height: auto;
  cursor: pointer;
}
.project .window.main .window.gallery {
  @apply mb-8 mt-0;
  width: calc(100% + 5rem);
  margin-left: -2.5rem;
  max-width: unset;
}
.project .window.main .window.gallery .innerWrap {
  @apply flex gap-3 justify-center items-center flex-wrap;
}
.project .window.main .window.gallery img {
  @apply border;
  height: 100px;
  width: auto;
  cursor: pointer;
}

.window {
  @apply w-full h-full border border-neutral relative z-10;
}
.window .cornerWrap {
  @apply absolute top-0 left-0 h-full w-full overflow-hidden;
}
.window.hidden {
  display: none;
}
.window .menuBar {
  @apply flex gap-4 py-2 px-4 uppercase;
  letter-spacing: 0.1rem;
}
.window .menuBar a::first-letter {
  font-weight: 900;
  text-decoration: underline;
}
.window .inner {
  @apply m-2.5 border border-neutral relative opacity-100 flex flex-col;
  height: calc(100% - 22px);
  min-height: 200px;
}
.window .innerWrap {
  @apply p-3 grow pb-5;
  overflow-y: auto;
  max-height: 100%;
}
.window .bar {
  @apply w-full flex items-center justify-between;
  flex-shrink: 0;
}
.window .bar p,
.window .bar a {
  @apply my-0 mx-auto font-bold uppercase p-1 block;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}
.window .bar .title {
  text-wrap: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 66px);
}
.window .bar .barButton,
.window .bar .arrowBtn {
  @apply flex justify-center items-center;
  height: 33px;
  width: 33px;
}
.window .bar .barButton .dash,
.window .bar .arrowBtn .dash {
  height: 6px;
  width: 15px;
  pointer-events: none;
}
.window .bar .barButton .arrow,
.window .bar .arrowBtn .arrow {
  height: 10px;
  width: 10px;
  background-image: linear-gradient(45deg, #ff1493 50%, transparent 50%);
}
.window .bar .barButton .right,
.window .bar .arrowBtn .right {
  transform: translateX(-3px) rotate(225deg);
}
.window .bar .barButton .down,
.window .bar .arrowBtn .down {
  transform: translateY(-3px) rotate(-45deg);
}
.window .bar .barButton .up,
.window .bar .arrowBtn .up {
  transform: translateY(3px) rotate(135deg);
}
.window .bar .arrowBtn:hover .arrow {
  background-image: linear-gradient(45deg, #a300ef 50%, transparent 50%);
}
.window .bar .arrowBtn:active .arrow {
  background-image: linear-gradient(45deg, #00ffff 50%, transparent 50%);
}
.window .corner {
  @apply absolute opacity-100;
  width: 38px;
  height: 41px;
}
.window .tags {
  @apply flex gap-3 items-center;
}
.window .tags a.tag {
  @apply btn;
}

.about .portrait {
  @apply mb-6 mt-3 -ml-12;
  max-width: 400px;
  float: left;
  margin-right: 2rem;
}
.about .portrait .inner {
  overflow: hidden;
}
.about .main {
  width: 1024px;
  margin: 0 auto;
}
.about .main .inner,
.about .main .innerWrap {
  overflow: visible;
}
.about .main .innerWrap {
  @apply p-4;
}
.about .main .innerWrap p {
  @apply mb-3;
}

.contact {
  @apply h-full w-full flex justify-center items-center;
}
.contact .main {
  @apply h-fit;
}
.contact .main form {
  @apply p-5 flex flex-col gap-3;
}
.contact .main form label {
  @apply input input-bordered flex items-center gap-2 w-4/5 mx-auto bg-transparent;
  height: unset;
}
.contact .main form label input,
.contact .main form label textarea {
  @apply m-3;
}
.contact .main form label.message {
  @apply h-fit pr-0 items-start;
}
.contact .main form label.message span {
  @apply mt-3;
}
.contact .main form label.message textarea {
  @apply grow bg-transparent m-0 ml-3;
  min-height: 150px;
}
.contact .main form label.message textarea:focus {
  border: none;
  outline: none;
}
.contact .success h3 {
  @apply text-center p-3 border-b text-2xl;
}
.contact .success p {
  @apply text-center p-3 mt-3;
}
.contact .success button {
  @apply btn btn-outline my-5 mx-auto block;
}

.bigImg {
  @apply fixed top-0 left-0 h-screen w-screen flex justify-center items-center backdrop-blur-sm;
  z-index: 999999;
}
.bigImg img {
  max-width: 80%;
  max-height: 80%;
  cursor: pointer;
}
.bigImg svg {
  cursor: pointer;
}
.bigImg .close {
  @apply absolute top-8 right-16;
}

.clock {
  @apply absolute;
  height: 375px;
  width: 300px;
  z-index: 999;
  top: 30vh;
  left: 80px;
}
.clock .clockInner {
  height: 250px;
}
.clock .timeDisplay {
  @apply text-center text-2xl;
}
.clock .center {
  @apply absolute;
  height: 30px;
  width: 30px;
  border-radius: 100px;
  top: 135px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 25;
}
.clock .hand {
  position: absolute;
  height: 150px;
  width: 1px;
  top: 75px;
  left: 50%;
  transform: translateX(-50%);
  transition: none;
}
.clock .second::before {
  height: 100px;
  margin-top: -15px;
  display: block;
  content: "";
}
.clock .minute::before {
  height: 130px;
  width: 8px;
  margin-top: -20px;
  display: block;
  content: "";
  transform: translateX(-2px);
}
.clock .hour::before {
  height: 100px;
  margin-top: 5px;
  width: 11px;
  display: block;
  content: "";
  transform: translateX(-4px);
}
.clock .inner .mark {
  height: 200px;
  width: 1px;
  position: absolute;
  top: 50px;
  left: 138px;
}
.clock .inner .mark::before {
  @apply absolute top-0 border w-3 h-3;
  content: "";
  border-radius: 50px;
  left: -5px;
}
.clock .inner .mark:nth-of-type(3n + 3)::before {
  @apply h-3.5 w-3.5;
  left: -5px;
}
.clock .inner .mark:nth-of-type(1) {
  transform: rotate(30deg);
}
.clock .inner .mark:nth-of-type(1)::before {
  transform: rotate(-30deg);
}
.clock .inner .mark:nth-of-type(2) {
  transform: rotate(60deg);
}
.clock .inner .mark:nth-of-type(2)::before {
  transform: rotate(-60deg);
}
.clock .inner .mark:nth-of-type(3) {
  transform: rotate(90deg);
}
.clock .inner .mark:nth-of-type(3)::before {
  transform: rotate(-90deg);
}
.clock .inner .mark:nth-of-type(4) {
  transform: rotate(120deg);
}
.clock .inner .mark:nth-of-type(4)::before {
  transform: rotate(-120deg);
}
.clock .inner .mark:nth-of-type(5) {
  transform: rotate(150deg);
}
.clock .inner .mark:nth-of-type(5)::before {
  transform: rotate(-150deg);
}
.clock .inner .mark:nth-of-type(6) {
  transform: rotate(180deg);
}
.clock .inner .mark:nth-of-type(6)::before {
  transform: rotate(-180deg);
}
.clock .inner .mark:nth-of-type(7) {
  transform: rotate(210deg);
}
.clock .inner .mark:nth-of-type(7)::before {
  transform: rotate(-210deg);
}
.clock .inner .mark:nth-of-type(8) {
  transform: rotate(240deg);
}
.clock .inner .mark:nth-of-type(8)::before {
  transform: rotate(-240deg);
}
.clock .inner .mark:nth-of-type(9) {
  transform: rotate(270deg);
}
.clock .inner .mark:nth-of-type(9)::before {
  transform: rotate(-270deg);
}
.clock .inner .mark:nth-of-type(10) {
  transform: rotate(300deg);
}
.clock .inner .mark:nth-of-type(10)::before {
  transform: rotate(-300deg);
}
.clock .inner .mark:nth-of-type(11) {
  transform: rotate(330deg);
}
.clock .inner .mark:nth-of-type(11)::before {
  transform: rotate(-330deg);
}

.calc {
  @apply absolute;
  z-index: 160;
  height: 450px;
  width: 350px;
  top: 250px;
  left: 400px;
}
.calc .calcInner {
  @apply flex flex-col;
  height: calc(100% - 36px);
}
.calc .screen {
  @apply w-10/12 h-10 py-1 px-2 flex justify-end items-center text-2xl border mx-auto my-2;
}
.calc button {
  @apply flex justify-center items-center text-center text-base rounded-lg my-1 mx-0 border;
}
.calc .buttons {
  @apply w-full grid grid-cols-4 grid-rows-5 gap-2 p-3 grow;
}
.calc .number {
  @apply w-full h-full;
}
.calc .number.clear {
  grid-column: 1/3;
  grid-row: 1/2;
}
.calc .number.zero {
  grid-column: 1/3;
  grid-row: 5/6;
}
.calc .operand {
  @apply w-full h-full;
  grid-column: 4/4;
}
.calc .operand.divide {
  grid-row: 1/2;
}
.calc .operand.times {
  grid-row: 2/3;
}
.calc .operand.minus {
  grid-row: 3/4;
}
.calc .operand.add {
  grid-row: 4/5;
}
.calc .operand.equal {
  grid-row: 5/6;
}

.spinnerWrap {
  @apply flex h-full w-full justify-center items-center;
}

.spinner {
  @apply h-14 w-14 border-8;
  border-radius: 100%;
  animation: rotation 2s infinite;
}
.spinner.small {
  height: 25px;
  width: 25px;
  border-width: 7px;
}
.spinner.large {
  height: 100px;
  width: 100px;
}

footer {
  @apply flex flex-col justify-center items-center w-full pb-16 mt-8 gap-8;
}
footer .icons {
  @apply flex gap-24;
}
footer .links {
  @apply flex gap-8 text-xl uppercase;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@media (max-width: 1395px) {
  .home .about .techWrap .item {
    height: 28%;
  }
}
@media (max-width: 1230px) {
  .home .about .techWrap {
    @apply p-0;
  }
}
@media (max-width: 1170px) {
  .home .desktopIcons .gitHub {
    grid-row: 3/4;
  }
  .page .projects,
  .page .posts, .page.about {
    @apply w-full;
  }
  .page.about .main {
    @apply w-full;
  }
}
@media (max-width: 901px) {
  .home {
    @apply grid p-5 gap-8;
    margin-top: 50px;
  }
  .home .draggable,
  .home .desktopIcons {
    @apply relative h-fit w-full;
  }
  .home .switch {
    @apply fixed right-5;
  }
  .home .portfolio {
    @apply left-0 top-0 w-full h-fit;
  }
  .home .logoBlock {
    @apply relative mx-auto;
    grid-row: 2/3;
  }
  .home .blog .inner {
    height: 300px;
  }
  .home .about .techWrap {
    @apply p-5;
  }
  .home .about .techWrap .item {
    height: 70px;
  }
  .home .blog,
  .home .about,
  .home .logoBlock,
  .home .portfolio {
    right: unset;
    top: unset;
    left: unset;
    bottom: unset;
  }
  .home .desktopIcons {
    @apply flex flex-wrap mt-8 gap-8 justify-center mb-24;
  }
  .home .desktopIcons .icon {
    @apply w-1/12;
  }
  .home .desktopIcons .clockIcon,
  .home .desktopIcons .calcIcon {
    display: none;
  }
  .home .desktopIcons .socialIcon {
    @apply mb-8 w-1/5;
  }
  .page h1 {
    @apply w-full ml-0 mb-8 text-center;
  }
  .page .window {
    @apply mt-4 w-full;
  }
  .page .window:first-of-type {
    @apply mt-0;
  }
  .page .window:nth-of-type(2n + 1), .page .window:nth-of-type(2n) {
    margin-right: 0;
    margin-left: 0;
  }
  .about .portrait {
    margin-right: 2rem !important;
    margin-left: -2rem !important;
  }
  .projects {
    @apply grid grid-cols-2 gap-6;
    max-width: 100%;
  }
  .projects .window {
    @apply w-full mt-0;
    max-width: 100%;
  }
  .contact {
    @apply block mb-16;
    height: auto;
  }
  .contact .main form label {
    @apply w-full;
  }
  .contact .main form .message {
    @apply mb-3;
  }
}
@media (max-width: 685px) {
  header .siteName {
    display: none;
  }
  .about .portrait {
    float: none;
  }
  footer .icons,
  footer .links {
    @apply gap-3 justify-between w-full px-5 flex-wrap;
  }
}
@media (max-width: 540px) {
  header {
    z-index: 999999;
  }
  header .mobile {
    @apply block;
  }
  header .mobile:hover {
    @apply text-accent;
    cursor: pointer;
  }
  header nav {
    @apply absolute flex-col border left-3 gap-0 w-fit border-t-0 hidden;
    top: 57px;
    z-index: 999999;
  }
  header nav a {
    @apply py-4 px-16;
  }
  header nav.show {
    @apply flex;
  }
  body.bright header nav {
    @apply bg-base-100 border-neutral;
  }
  body.dark header {
    @apply text-primary;
  }
  body.dark header nav {
    @apply bg-neutral border-primary;
  }
}
@media (max-width: 505px) {
  #container.pageContain {
    height: calc(100vh - 58px);
    margin-top: 58px;
  }
  .home .logoBlock {
    @apply text-center;
    width: 100%;
    grid-row: 1/2;
  }
  .home .blog {
    display: none;
  }
  .home .desktopIcons .icon {
    width: auto;
  }
  .home .desktopIcons .socialIcon {
    width: 18%;
  }
  .projects {
    @apply block;
  }
  .projects .window {
    @apply mb-5;
  }
  .project .window.main .body {
    @apply flex-col-reverse;
  }
  .project .window.main .tech {
    margin-bottom: 0;
    margin-right: 0;
    width: 100%;
  }
  footer {
    margin-bottom: 5rem;
  }
  footer .icons,
  footer .links {
    @apply justify-center gap-5;
  }
  footer .icons svg,
  footer .links svg {
    height: 40px;
    width: auto;
  }
}
@media only screen and (-webkit-device-pixel-ratio: 2) {
  footer {
    margin-bottom: 5rem;
  }
}
@media only screen and (-webkit-device-pixel-ratio: 3) {
  footer {
    margin-bottom: 5rem;
  }
}

